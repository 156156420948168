<template>
  <div class="help">
    <h3 class="mt-4 mb-4">Add User</h3>
    <b-form @submit="onSubmit">
      <b-form-group
        id="fg-email"
        label="Email Address"
        label-for="email"
      >
        <b-form-input
          id="email"
          type="email"
          v-model="email"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fg-first-name"
        label="First Name"
        label-for="first-name"
      >
        <b-form-input
          id="first-name"
          v-model="first_name"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="fg-last-name"
        label="Last Name"
        label-for="last-name"
      >
        <b-form-input
          id="last-name"
          v-model="last_name"
        ></b-form-input>
      </b-form-group>


      <b-form-group
        id="fg-use-ss"
        label="Use SDWIS/STATE"
        label-for="use-ss"
      >
        <b-form-checkbox
          id="use-ss"
          v-model="use_sdwis"
        ></b-form-checkbox>
      </b-form-group>

      <b-button type="submit" variant="primary">Submit Request</b-button>
    </b-form>
    <div class="mt-3">
    <b-alert :show="success_msg.length" variant="success">Success</b-alert>
    <b-alert :show="error_msg.length" variant="danger">{{ error_msg }}</b-alert>
  </div>
  </div>
</template>
<script>

export default {
    name: "AddUser",
    data() {
      return {
        email: '',
        first_name: '',
        last_name: '',
        use_sdwis: true,
        error_msg: '',
        success_msg: '',
        data_submitted: false
      }
    },
    methods: {
      async onSubmit(event) {
        event.preventDefault()
        const path = process.env.VUE_APP_API_URI + "/adduser"
        const params = {
          email: this.email,
          roles: ['MSDH'],
        }
        console.log(this.use_sdwis)
        if (!this.use_sdwis) {
          params.use_sdwis = this.use_sdwis
          params.first_name = this.first_name
          params.last_name = this.last_name
        }
        console.log(params)
        try {
          let response = await this.axios.post(path, params)
          console.log(response)
          if (response.data) {
            console.log(response.data)
            let msg = response.data.msg
            if (msg == 'Success') {
              this.success_msg = response.data.msg
            }
            else {
              this.error_msg = response.data.msg
            }
          }
        } catch (error) {
          this.data_submitted = false
          this.error_msg = true
          console.log(error)
        }
      }
    }
};
</script>
